import React, { useState, useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = (theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 340,
  },
  submitBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "60px",
  },
});

//Carrier Apis
const noneApi = {
  id: "none",
  name: "None",
};

const routigoApi = {
  id: "routigo",
  name: "Routigo",
};

const viatimApi = {
  id: "viatim",
  name: "VIATIM",
};

const veloyedApi = {
  id: "veloyd",
  name: "Veloyd",
};

const packalyApi = {
  id: "packaly",
  name: "Packaly",
};

const fairsendenApi = {
  id: "fairsenden",
  name: "Fairsenden",
};

const instaboxApi = {
  id: "instabox",
  name: "Instabox",
};

const instaboxV2Api = {
  id: "instaboxV2",
  name: "InstaboxV2",
};

const nextUpApi = {
  id: "nextUp",
  name: "NextUp",
};

const budbeeApi = {
  id: "budbee",
  name: "Budbee",
};

const trunkrsApi = {
  id: "trunkrs",
  name: "Trunkrs",
};

const dhlForYouNextDayApi = {
  id: "dhlForYouNextDay",
  name: "DHL For you Next Day",
};

const dhlQRApi = {
  id: "dhlQR",
  name: "DHL QR",
};

const veloydLineHaulApi = {
  id: "veloydLineHaul",
  name: "Veloyd Line Haul",
};

const cycloonApi = {
  id: "cycloon",
  name: "Cycloon",
};

const peddlerSFSApi = {
  id: "peddlerSFS",
  name: "Peddler SFS",
};

const postNLApi = {
  id: "postNL",
  name: "PostNL",
};

const peddlerSFWApi = {
  id: "peddlerSFW",
  name: "Peddler SFW",
};

const qlsApi = {
  id: "qls",
  name: "QLS",
};

//Carrier types
const localCarrierType = {
  id: "LOCAL",
  displayName: "Local",
};

const nationalCarrierType = {
  id: "NATIONAL",
  displayName: "National",
};

const internationalCarrierType = {
  id: "INTERNATIONAL",
  displayName: "Interntional",
};

const regionalCarrierType = {
  id: "REGIONAL",
  displayName: "Regional",
};

const carrierTypes = [
  localCarrierType,
  nationalCarrierType,
  regionalCarrierType,
  internationalCarrierType,
];

const vehicleTypes = [
  { id: "unknown", displayName: "Unknown" },
  { id: "hybrid", displayName: "Hybrid" },
  { id: "bikePlusEV", displayName: "Bike Plus EV" },
  { id: "cargobike", displayName: "Cargo Bike" },
];

const apis = [
  noneApi,
  routigoApi,
  veloyedApi,
  packalyApi,
  viatimApi,
  fairsendenApi,
  instaboxApi,
  instaboxV2Api,
  nextUpApi,
  budbeeApi,
  trunkrsApi,
  dhlForYouNextDayApi,
  veloydLineHaulApi,
  dhlQRApi,
  cycloonApi,
  peddlerSFSApi,
  postNLApi,
  peddlerSFWApi,
  qlsApi,
];

const decodeBase64 = (base64) => {
  try {
    return atob(base64);
  } catch (error) {
    console.error("Error decoding base64", error);
  }
  return;
};

const CarrierForm = ({
  carrier,
  edit,
  isCreatingNew,
  tagValue,
  handleEditCarrier,
  handleSave,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (edit) {
      const viatim =
        carrier.carrierApi && carrier.carrierApi.viatim
          ? {
              carrierApi: viatimApi.id,
              apiKey: carrier.carrierApi.viatim.apiKey,
            }
          : {};
      const routigo =
        carrier.carrierApi && carrier.carrierApi.routigo
          ? {
              carrierApi: routigoApi.id,
              apiKey: carrier.carrierApi.routigo.apiKey,
              routeId: carrier.carrierApi.routigo.routeId,
              svcId: carrier.carrierApi.routigo.svcId,
            }
          : {};
      const veloyd =
        carrier.carrierApi && carrier.carrierApi.veloyd
          ? {
              carrierApi: veloyedApi.id,
              apiKey: carrier.carrierApi.veloyd.apiKey,
              scanAtThePickup:
                getFieldFromProperties("scanAtThePickup").toLowerCase() ===
                "true",
            }
          : {};
      const packaly =
        carrier.carrierApi && carrier.carrierApi.packaly
          ? {
              carrierApi: packalyApi.id,
              client_id: carrier.carrierApi.packaly.client_id,
              client_secret: carrier.carrierApi.packaly.client_secret,
            }
          : {};

      const fairsenden =
        carrier.carrierApi && carrier.carrierApi.fairsenden
          ? {
              carrierApi: fairsendenApi.id,
              client_id: carrier.carrierApi.fairsenden.client_id,
              client_secret: carrier.carrierApi.fairsenden.client_secret,
            }
          : {};
      const instabox =
        carrier.carrierApi && carrier.carrierApi.instabox
          ? {
              carrierApi: instaboxApi.id,
              apiKey: carrier.carrierApi.instabox.apiKey,
            }
          : {};
      const instaboxV2 =
        carrier.carrierApi && carrier.carrierApi.instaboxV2
          ? {
              carrierApi: instaboxV2Api.id,
              client_id: carrier.carrierApi.instaboxV2.client_id,
              client_secret: carrier.carrierApi.instaboxV2.client_secret,
            }
          : {};
      const budbee =
        carrier.carrierApi && carrier.carrierApi.budbee
          ? {
              carrierApi: budbeeApi.id,
              username: getFieldFromProperties("username"),
              password: getFieldFromProperties("password"),
              collectionId: getFieldFromProperties("collectionId"),
            }
          : {};

      const nextUp =
        carrier.carrierApi && carrier.carrierApi.nextUp
          ? {
              carrierApi: nextUpApi.id,
              apiKey: carrier.carrierApi.nextUp.apiKey,
              customerId: getFieldFromProperties("customerId"),
              packageType: getFieldFromProperties("packageType"),
              depot: getFieldFromProperties("depot"),
            }
          : {};
      const trunkrs =
        carrier.carrierApi && carrier.carrierApi.trunkrs
          ? {
              carrierApi: trunkrsApi.id,
              apiKey: carrier.carrierApi.trunkrs.apiKey,
            }
          : {};
      const dhlForYouNextDay =
        carrier.carrierApi && carrier.carrierApi.dhlForYouNextDay
          ? {
              carrierApi: dhlForYouNextDayApi.id,
              client_id: carrier.carrierApi.dhlForYouNextDay.client_id,
              client_secret: carrier.carrierApi.dhlForYouNextDay.client_secret,
              accountNumber: getFieldFromProperties("accountNumber"),
              companyName: getFieldFromProperties("companyName"),
              configurationId: getFieldFromProperties("configurationId"),
              subscriptionKey: getFieldFromProperties("subscriptionKey"),
              webhookApiKey: getFieldFromProperties("webhookApiKey"),
            }
          : {};
      const dhlQR =
        carrier.carrierApi && carrier.carrierApi.dhlQR
          ? {
              carrierApi: dhlQRApi.id,
              client_id: carrier.carrierApi.dhlQR.client_id,
              client_secret: carrier.carrierApi.dhlQR.client_secret,
              accountNumber: getFieldFromProperties("accountNumber"),
              companyName: getFieldFromProperties("companyName"),
              configurationId: getFieldFromProperties("configurationId"),
              subscriptionKey: getFieldFromProperties("subscriptionKey"),
              webhookApiKey: getFieldFromProperties("webhookApiKey"),
            }
          : {};
      const veloydLineHaul =
        carrier.carrierApi && carrier.carrierApi.veloydLineHaul
          ? {
              carrierApi: veloydLineHaulApi.id,
              lineHaulApiKey: getFieldFromProperties("lineHaulApiKey"),
              lastMileApiKey: getFieldFromProperties("lastMileApiKey"),
              lastMileCreatedOn: getFieldFromProperties("lastMileCreatedOn"),
            }
          : {};
      const cycloon =
        carrier.carrierApi && carrier.carrierApi.cycloon
          ? {
              carrierApi: cycloonApi.id,
              username: getFieldFromProperties("username"),
              apiKey: carrier.carrierApi.cycloon.apiKey,
            }
          : {};
      const peddlerSFS =
        carrier.carrierApi && carrier.carrierApi.peddlerSFS
          ? {
              carrierApi: peddlerSFSApi.id,
              peddlerRSAKey: getFieldFromProperties("peddlerRSAKey"),
              peddlerSubject: getFieldFromProperties("peddlerSubject"),
              peddlerIssuer: getFieldFromProperties("peddlerIssuer"),
            }
          : {};

      const postNL =
        carrier.carrierApi && carrier.carrierApi.postNL
          ? {
              carrierApi: postNLApi.id,
              customerCode: getFieldFromProperties("customerCode"),
              customerNumber: getFieldFromProperties("customerNumber"),
              collectionId: getFieldFromProperties("collectionId"),
              webhookApiKey: getFieldFromProperties("webhookApiKey"),
              apiKey: carrier.carrierApi.postNL.apiKey,
            }
          : {};

      const peddlerSFW =
        carrier.carrierApi && carrier.carrierApi.peddlerSFW
          ? {
              carrierApi: peddlerSFWApi.id,
              client_id: carrier.carrierApi.peddlerSFW.client_id,
              client_secret: carrier.carrierApi.peddlerSFW.client_secret,
              carrierId: getFieldFromProperties("carrierId"),
            }
          : {};
      const qls =
        carrier.carrierApi && carrier.carrierApi.qls
          ? {
              carrierApi: qlsApi.id,
              username: getFieldFromProperties("username"),
              password: getFieldFromProperties("password"),
              companyId: getFieldFromProperties("companyId"),
              productId: getFieldFromProperties("productId"),
            }
          : {};

      setFormData({
        /* ...formData, */ ...carrier,
        ...routigo,
        ...veloyd,
        ...packaly,
        ...viatim,
        ...fairsenden,
        ...instabox,
        ...instaboxV2,
        ...nextUp,
        ...budbee,
        ...trunkrs,
        ...dhlForYouNextDay,
        ...veloydLineHaul,
        ...dhlQR,
        ...cycloon,
        ...peddlerSFS,
        ...postNL,
        ...peddlerSFW,
        ...qls,
      });
    }
  }, [edit]);

  const getFieldFromProperties = (fieldName) => {
    if (!carrier.properties) {
      return "";
    }
    const property = carrier.properties.find(
      (property) => property.name === fieldName,
    );
    if (property) {
      return property.value;
    }
    return "";
  };

  const handleFormData = useCallback(
    (key) => (e) => {
      const {
        target: { value },
      } = e;
      if (key === "carrierApi" && value === noneApi.id) {
        setFormData({
          ...formData,
          [key]: e.target.value,
          apiKey: "",
        });
        return;
      }
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData],
  );
  const handleCheckbox = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.checked });
    },
    [formData],
  );
  const setButtonValue = () => {
    if (edit && tagValue === 0) {
      return "Update";
    } else if (tagValue === 1) {
      return "";
    } else {
      return "Save";
    }
  };
  const submit = () =>
    edit ? handleEditCarrier(formData) : handleSave(formData);
  const isClientAndSecretId =
    formData.carrierApi === fairsendenApi.id ||
    formData.carrierApi === instaboxV2Api.id ||
    formData.carrierApi === dhlForYouNextDayApi.id ||
    formData.carrierApi === dhlQRApi.id ||
    formData.carrierApi === peddlerSFWApi.id;
  return (
    <>
      <Button
        data-testid="save_btn"
        sx={styles.submitBtn}
        autoFocus
        color="inherit"
        onClick={submit}
      >
        {setButtonValue()}
        {isCreatingNew && <ButtonProgress size={24} />}
      </Button>
      <form style={sharedStyles.container}>
        <FormControl sx={sharedStyles.formControl}>
          <Typography variant="h3" component="h4">
            {edit ? "Edit Carrier" : " Create new Carrier"}
          </Typography>
          <Grid container spacing={2} sx={styles.formContainer}>
            <Grid item xs={12}>
              <TextField
                id="carrier-name"
                data-testid="carrier_name"
                variant="standard"
                label="Name"
                fullWidth
                value={formData.name || ""}
                onChange={handleFormData("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carrier-export-name"
                data-testid="carrier-export-name"
                variant="standard"
                label="Export Name"
                fullWidth
                value={formData.exportName || ""}
                onChange={handleFormData("exportName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="notification-email"
                data-testid="notification_email_field"
                variant="standard"
                label="Notification Email"
                fullWidth
                value={formData.notificationEmail || ""}
                onChange={handleFormData("notificationEmail")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="reminder-email"
                data-testid="reminder_email_field"
                variant="standard"
                label="Reminder Email"
                fullWidth
                value={formData.reminderEmail || ""}
                onChange={handleFormData("reminderEmail")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="master-email"
                data-testid="master_email_field"
                variant="standard"
                label="Master Email"
                fullWidth
                value={formData.masterEmail || ""}
                onChange={handleFormData("masterEmail")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="deliver_sat_cb"
                    variant="standard"
                    checked={!!formData.deliversOnSat || false}
                    onChange={handleCheckbox("deliversOnSat")}
                    name="deliversOnSat"
                    color="primary"
                  />
                }
                label="Delivers on Sat"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="deliver_sun_cb"
                    variant="standard"
                    checked={!!formData.deliversOnSun || false}
                    onChange={handleCheckbox("deliversOnSun")}
                    name="deliversOnSun"
                    color="primary"
                  />
                }
                label="Delivers on Sunday"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="pickup_sat_cb"
                    variant="standard"
                    checked={!!formData.pickupOnSat || false}
                    onChange={handleCheckbox("pickupOnSat")}
                    name="pickupOnSat"
                    color="primary"
                  />
                }
                label="Pickup on Sat"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="pickup_sun_cb"
                    variant="standard"
                    checked={!!formData.pickupOnSun || false}
                    onChange={handleCheckbox("pickupOnSun")}
                    name="pickupOnSun"
                    color="primary"
                  />
                }
                label="Pickup on Sunday"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-testid="carrier_type_input"
                id="carrierType"
                variant="standard"
                select
                label="Carrier Type"
                margin="normal"
                fullWidth
                value={formData.carrierType || localCarrierType.id}
                onChange={handleFormData("carrierType")}
              >
                {carrierTypes.map((cType) => (
                  <MenuItem value={cType.id} key={cType.displayName}>
                    {cType.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="vehicle-type"
                variant="standard"
                select
                label="Vehicle Type"
                fullWidth
                value={formData.vehicleType || vehicleTypes[0].id}
                onChange={handleFormData("vehicleType")}
              >
                {vehicleTypes.map((vType) => (
                  <MenuItem value={vType.id} key={vType.id}>
                    {vType.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carrierApi"
                variant="standard"
                select
                label="Carrier API"
                margin="normal"
                fullWidth
                value={formData.carrierApi || noneApi.id}
                onChange={handleFormData("carrierApi")}
              >
                {apis.map((carrierApi) => (
                  <MenuItem value={carrierApi.id} key={carrierApi.id}>
                    {carrierApi.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {formData.carrierApi !== "packaly" &&
              formData.carrierApi !== "fairsenden" &&
              formData.carrierApi !== "budbee" &&
              formData.carrierApi !== "peddlerSFS" &&
              formData.carrierApi !== "instaboxV2" &&
              formData.carrierApi !== "dhlForYouNextDay" &&
              formData.carrierApi !== "dhlQR" &&
              formData.carrierApi !== "veloydLineHaul" &&
              formData.carrierApi !== "peddlerSFS" &&
              formData.carrierApi !== "peddlerSFW" &&
              formData.carrierApi !== "qls" && (
                <Grid item xs={12}>
                  <TextField
                    id="carrierApiKey"
                    variant="standard"
                    label="Carrier APIKey"
                    margin="normal"
                    fullWidth
                    value={formData.apiKey || ""}
                    onChange={handleFormData("apiKey")}
                  />
                </Grid>
              )}

            {formData.carrierApi === "routigo" && (
              <Grid item xs={12}>
                <TextField
                  id="routigoRouteId"
                  variant="standard"
                  label="Route Id"
                  margin="normal"
                  fullWidth
                  value={formData.routeId || ""}
                  onChange={handleFormData("routeId")}
                />
              </Grid>
            )}
            {formData.carrierApi === "routigo" && (
              <Grid item xs={12}>
                <TextField
                  id="routigoSVCId"
                  variant="standard"
                  label="Route SVC Id"
                  margin="normal"
                  fullWidth
                  value={formData.svcId || ""}
                  onChange={handleFormData("svcId")}
                />
              </Grid>
            )}
            {formData.carrierApi === packalyApi.id && (
              <Grid item xs={12}>
                <TextField
                  id="packalyClientId"
                  variant="standard"
                  label="Client Id"
                  margin="normal"
                  fullWidth
                  value={formData.client_id || ""}
                  onChange={handleFormData("client_id")}
                />
              </Grid>
            )}
            {formData.carrierApi === veloyedApi.id && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="scanAtThePickupId"
                        checked={!!formData.scanAtThePickup}
                        onChange={handleCheckbox("scanAtThePickup")}
                        name="scanAtThePickup"
                        color="primary"
                      />
                    }
                    label="Scan at the pickup"
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === nextUpApi.id && (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="Package type"
                    margin="normal"
                    fullWidth
                    value={formData.packageType || ""}
                    onChange={handleFormData("packageType")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="Customer Id"
                    margin="normal"
                    fullWidth
                    value={formData.customerId || ""}
                    onChange={handleFormData("customerId")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="Depot"
                    margin="normal"
                    fullWidth
                    value={formData.depot || ""}
                    onChange={handleFormData("depot")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "packaly" && (
              <Grid item xs={12}>
                <TextField
                  id="packalyClientSecret"
                  variant="standard"
                  label="Client Secret Key"
                  margin="normal"
                  fullWidth
                  value={formData.client_secret || ""}
                  onChange={handleFormData("client_secret")}
                />
              </Grid>
            )}
            {/* Fairsenden */}

            {isClientAndSecretId && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="commonClientId"
                    variant="standard"
                    label="Client Id"
                    margin="normal"
                    fullWidth
                    value={formData.client_id || ""}
                    onChange={handleFormData("client_id")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="CommonClientSecret"
                    variant="standard"
                    label="Client Secret Key"
                    margin="normal"
                    fullWidth
                    value={formData.client_secret || ""}
                    onChange={handleFormData("client_secret")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "budbee" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="budbeeUsername"
                    variant="standard"
                    label="Username"
                    margin="normal"
                    fullWidth
                    value={formData.username || ""}
                    onChange={handleFormData("username")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="budBeePassword"
                    variant="standard"
                    label="Password"
                    margin="normal"
                    fullWidth
                    value={formData.password || ""}
                    onChange={handleFormData("password")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="budBeeCollectionId"
                    variant="standard"
                    label="Collection Id"
                    margin="normal"
                    fullWidth
                    value={formData.collectionId || ""}
                    onChange={handleFormData("collectionId")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "dhlForYouNextDay" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="accountNumber"
                    variant="standard"
                    label="Account Number"
                    margin="normal"
                    fullWidth
                    value={formData.accountNumber || ""}
                    onChange={handleFormData("accountNumber")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="companyName"
                    variant="standard"
                    label="Company Name"
                    margin="normal"
                    fullWidth
                    value={formData.companyName || ""}
                    onChange={handleFormData("companyName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="configurationId"
                    variant="standard"
                    label="Configuration Id"
                    margin="normal"
                    fullWidth
                    value={formData.configurationId || ""}
                    onChange={handleFormData("configurationId")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="subscriptionKey"
                    variant="standard"
                    label="Subscription Key"
                    margin="normal"
                    fullWidth
                    value={formData.subscriptionKey || ""}
                    onChange={handleFormData("subscriptionKey")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="webhookApiKey"
                    variant="standard"
                    label="Webhook Api Key"
                    margin="normal"
                    fullWidth
                    value={formData.webhookApiKey || ""}
                    onChange={handleFormData("webhookApiKey")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "dhlQR" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="accountNumber"
                    variant="standard"
                    label="Account Number"
                    margin="normal"
                    fullWidth
                    value={formData.accountNumber || ""}
                    onChange={handleFormData("accountNumber")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="companyName"
                    variant="standard"
                    label="Company Name"
                    margin="normal"
                    fullWidth
                    value={formData.companyName || ""}
                    onChange={handleFormData("companyName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="configurationId"
                    variant="standard"
                    label="Configuration Id"
                    margin="normal"
                    fullWidth
                    value={formData.configurationId || ""}
                    onChange={handleFormData("configurationId")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="subscriptionKey"
                    variant="standard"
                    label="Subscription Key"
                    margin="normal"
                    fullWidth
                    value={formData.subscriptionKey || ""}
                    onChange={handleFormData("subscriptionKey")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="webhookApiKeyDhlQR"
                    variant="standard"
                    label="Webhook Api Key"
                    margin="normal"
                    fullWidth
                    value={formData.webhookApiKey || ""}
                    onChange={handleFormData("webhookApiKey")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "veloydLineHaul" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="lineHaulApiKey"
                    variant="standard"
                    label="Line Haul Api Key"
                    margin="normal"
                    fullWidth
                    value={formData.lineHaulApiKey || ""}
                    onChange={handleFormData("lineHaulApiKey")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="lastMileApiKey"
                    variant="standard"
                    label="Last Mile Api Key"
                    margin="normal"
                    fullWidth
                    value={formData.lastMileApiKey || ""}
                    onChange={handleFormData("lastMileApiKey")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="lastMileCreatedOn"
                    variant="standard"
                    select
                    label="Create last mile order on status"
                    fullWidth
                    value={formData.lastMileCreatedOn || "CONFIRMED"}
                    onChange={handleFormData("lastMileCreatedOn")}
                  >
                    <MenuItem value="CONFIRMED" key="CONFIRMED">
                      Confirmed
                    </MenuItem>
                    <MenuItem value="PICKED_UP" key="PICKED_UP">
                      Picked up
                    </MenuItem>
                  </TextField>
                </Grid>
              </>
            )}
            {formData.carrierApi === "cycloon" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="cycloonUserName"
                    variant="standard"
                    label="Username"
                    margin="normal"
                    fullWidth
                    value={formData.username || ""}
                    onChange={handleFormData("username")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "peddlerSFS" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={6}
                    id="peddlerSFSRSAKey"
                    variant="standard"
                    label="RSA Key"
                    margin="normal"
                    fullWidth
                    value={decodeBase64(formData.peddlerRSAKey) || ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        peddlerRSAKey: btoa(e.target.value),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="peddlerSFSIssuer"
                    variant="standard"
                    label="Issuer"
                    margin="normal"
                    fullWidth
                    value={formData.peddlerIssuer || ""}
                    onChange={handleFormData("peddlerIssuer")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="peddlerSFSSubject"
                    variant="standard"
                    label="Subject"
                    margin="normal"
                    fullWidth
                    value={formData.peddlerSubject || ""}
                    onChange={handleFormData("peddlerSubject")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "postNL" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="customerCode"
                    variant="standard"
                    label="Customer Code"
                    margin="normal"
                    fullWidth
                    value={formData.customerCode || ""}
                    onChange={handleFormData("customerCode")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="customerNumber"
                    variant="standard"
                    label="Customer Number"
                    margin="normal"
                    fullWidth
                    value={formData.customerNumber || ""}
                    onChange={handleFormData("customerNumber")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="collectionId"
                    variant="standard"
                    label="Collection Location Id"
                    margin="normal"
                    fullWidth
                    value={formData.collectionId || ""}
                    onChange={handleFormData("collectionId")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="webhookApiKey"
                    variant="standard"
                    label="Webhook Api Key"
                    margin="normal"
                    fullWidth
                    value={formData.webhookApiKey || ""}
                    onChange={handleFormData("webhookApiKey")}
                  />
                </Grid>
              </>
            )}
            {/* PeddlerSWH */}
            {formData.carrierApi === "peddlerSFW" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="peddlerSFWCarrierId"
                    variant="standard"
                    label="CarrierId"
                    margin="normal"
                    fullWidth
                    value={formData.carrierId || ""}
                    onChange={handleFormData("carrierId")}
                  />
                </Grid>
              </>
            )}
            {formData.carrierApi === "qls" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="qlsUserName"
                    variant="standard"
                    label="UserName"
                    margin="normal"
                    fullWidth
                    value={formData.username || ""}
                    onChange={handleFormData("username")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="qlsPassword"
                    variant="standard"
                    label="Password"
                    margin="normal"
                    fullWidth
                    value={formData.password || ""}
                    onChange={handleFormData("password")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="qlsCompanyId"
                    variant="standard"
                    label="Company Id"
                    margin="normal"
                    fullWidth
                    value={formData.companyId || ""}
                    onChange={handleFormData("companyId")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="qlsProductId"
                    variant="standard"
                    label="Product Id"
                    margin="normal"
                    fullWidth
                    value={formData.productId || ""}
                    onChange={handleFormData("productId")}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default CarrierForm;
