import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import "date-fns";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockIcon from "@mui/icons-material/Lock";
import FileDownload from "js-file-download";
import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
  deleteRequestUI,
  postRequestOrderExport,
} from "common-utils/utils/api";
import { formatAddress } from "common-utils/utils/formatter";
import {
  UpdateOrderStatus,
  Filters,
  OrdersTable,
  MainBox,
  ShippingLabelButton,
  Order2FactorCode,
  OrderSignatureDialog,
  OrderInfo,
  EditOrderStatus,
  DateFormatter,
} from "@shared/components/lib/index";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { FAILED_AT_CARRIER } from "../constants";
import EditOrder from "../components/EditOrder";
import { useQuery } from "react-query";
import UpdateIcon from "@mui/icons-material/Update";
import OrderETA from "../components/OrderETA";
import { TIME_SHOW_ERROR } from "common-utils/src/constants";
import { notDeliveredReason } from "common-utils/utils/updateOrder";
import { DateTime } from "luxon";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { useIsMount } from "common-utils/hooks/useIsMount";
import { useNavigate } from "react-router-dom";

function Orders(props) {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [editStatusDialog, setStatusEditDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [twoFactorDialogOpen, setTwoFactorDialogOpen] = useState(false);
  const [signatureDialogOpened, setSignatureDialogOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reason, setCurrentReason] = useState({});
  const [showError, setShowError] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date());
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(new Date());
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isFailedOrders, setIsFailedOrders] = useState(false);
  const [selectedZipCodeFilter, setSelectedZipCodeFilter] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState([]);
  const [selectedReasonFilter, setSelectedReasonFilter] = useState([]);
  const [selectedSizeFilter, setSelectedSizeFilter] = useState([]);
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState([]);
  const [selectedCarriersFilter, setSelectedCarriersFilter] = useState([]);
  const [selectedRetailersFilter, setSelectedRetailersFilter] = useState([]);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isOpenETA, setOpenETA] = useState(false);
  const [selectedTrackingCodeFilter, setSelectedTrackingCodeFilter] = useState(
    []
  );
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedFilterWarning, setSelectedFilterWarning] = useState(false);
  const [selectedFilterMissingSignature, setSelectedFilterMissingSignature] =
    useState(false);
  const [selectedFilterMissingGpsStamp, setSelectedFilterMissingGpsStamp] =
    useState(false);
  const [selectedFilterDelayedOrders, setSelectedFilterDelayedOrders] =
    useState(false);
  const [selectedFilterTwoFactorOrders, setSelectedFilterTwoFactorOrders] =
    useState(false);
  const [selectedFilterClaimOrders, setSelectedFilterClaimOrders] =
    useState(false);
  const [totalRowsNumber, setTotalRowsNumber] = useState(0);
  const [pagination, changePagination] = useState({
    page: 0,
    rowsPerPage: 20,
  });
  const prerequisites = useQuery(["initAdminOrderPrerequisites"], async () => {
    const result = await getRequestUI("/orders-prerequisites");
    return result;
  });
  const isMount = useIsMount();

  useEffect(() => {
    setShowError(false);
  }, [currentStatus]);

  useEffect(() => {
    initLoading();
  }, [prerequisites.data]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [pagination.page, pagination.rowsPerPage]);

  const initLoading = () => {
    if (
      !prerequisites.data ||
      (prerequisites.data && !prerequisites.data.statuses)
    ) {
      return;
    }
    const params = new URLSearchParams(location.search);
    const trackingCode = params.get("trackingCode");
    const startDateParam = params.get("startDate");
    const endDateParam = params.get("endDate");
    const statusesParam = params.get("statuses[]");
    const isFilterFromURL = startDateParam || endDateParam || statusesParam;
    const startDate = formatDateFromParam(startDateParam);
    const endDate = formatDateFromParam(endDateParam);
    const statuses = statusesParam ? statusesParam.split(",") : [];
    if (startDateParam) {
      setSelectedStartPickUpDateFilter(startDate);
    }
    if (endDateParam) {
      setSelectedEndPickUpDateFilter(endDate);
    }
    if (statusesParam) {
      const selectedStatuses = prerequisites.data.statuses.filter((status) =>
        statuses.includes(status.id)
      );
      setSelectedStatusFilter(selectedStatuses);
    }
    if (trackingCode) {
      setSelectedTrackingCodeFilter([trackingCode]);
      getOrdersByTrackingCodes(trackingCode);
      return;
    }
    if (isFilterFromURL) {
      const filter = {
        startDate: startDateParam,
        endDate: endDateParam,
        statuses,
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
      };
      updateTableByInit(filter);
    } else {
      updateTable();
    }
  };
  const formatDateFromParam = (date) => {
    return date ? new Date(date) : new Date();
  };
  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
    setSelectedOrders([]);
  };

  const handleStatusEditDialogClose = () => {
    setStatusEditDialogOpen(false);
  };

  const handleSignatureDialogClose = () => {
    setSignatureDialogOpen(false);
    setStatusEditDialogOpen(false);
  };

  useEffect(() => {
    const failedOrders = getFailedAtCarrierOrders();
    if (!!failedOrders.length) {
      setIsFailedOrders(true);
    } else {
      setIsFailedOrders(false);
    }
  }, [selectedOrders]);

  const getFailedAtCarrierOrders = () => {
    return orders.filter((order) => {
      const found = selectedOrders.find((sId) => order.id === sId);
      if (found && order.status.id === FAILED_AT_CARRIER) {
        return order;
      }
    });
  };

  const updateTableByInit = async (filters) => {
    try {
      setShowProgress(true);
      const response = await getRequestUI("/orders", filters);

      if (response.data) {
        setOrders(response.data);
        setTotalRowsNumber(response.totalNumbers);
      }
    } catch (error) {
      const message = error.message;
      setShowError(true);
      if (message) {
        setErrorMessage(message);
      }
    } finally {
      setShowProgress(false);
    }
  };

  const updateTable = () => {
    setShowProgress(true);
    return getRequestUI("/orders", getFilterData())
      .then((result) => {
        setShowProgress(false);
        setOrders(result.data);
        setTotalRowsNumber(result.totalNumbers);
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
      });
  };

  const resetPageNumAndUpdateTable = () => {
    resetPageNumber();
    updateTable();
  };

  const handleCloseEdit = () => {
    setErrorMessage("");
    setShowError(false);
    setEditDialogOpen(false);
    setCurrentOrder({});
  };

  const handleCloseInfo = () => {
    setErrorMessage("");
    setShowError(false);
    setOpenInfoDialog(false);
    setCurrentOrder({});
  };

  const handleCloseETA = () => {
    setErrorMessage("");
    setShowError(false);
    setCurrentOrder({});
    setOpenETA(false);
  };

  const saveStatus = (remark) => () => {
    console.log("STATUS:" + JSON.stringify(currentStatus));
    setUpdatingStatus(true);
    setShowError(false);
    if (reason && reason.id === notDeliveredReason && !remark) {
      setShowError(true);
      setErrorMessage("Remark is required");
      return;
    }
    const updateStatus = {
      orderIds: selectedOrders.length > 0 ? selectedOrders : [currentOrder.id],
      statusId: currentStatus.id,
      remark,
      reasonId: reason && reason.id,
      timestamp: selectedTime,
    };

    return putRequestUI("/status", updateStatus)
      .then(() => {
        updateTable();
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        setShowError(true);
        if (code === "invalid-argument") {
          setErrorMessage(message);
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setSelectedOrders([]);
        setStatusDialogOpen(false);
      });
  };

  const handleEditStatus = (statusId, newStatus) => {
    setUpdatingStatus(true);
    setShowError(false);
    putRequestUI(`/edit-order/${currentOrder.id}/status/${statusId}`, newStatus)
      .then(() => {
        updateTable();
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        setShowError(true);
        if (code === "invalid-argument") {
          setErrorMessage(message);
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setStatusEditDialogOpen(false);
        setSelectedOrders([]);
      });
  };

  const deleteStatus = (statusId) => {
    setUpdatingStatus(true);
    setShowError(false);
    deleteRequestUI(`/delete-order/${currentOrder.id}/status/${statusId}`)
      .then(() => {
        updateTable();
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        setShowError(true);
        if (code === "invalid-argument") {
          setErrorMessage(message);
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setStatusEditDialogOpen(false);
        setSelectedOrders([]);
      });
  };
  const openStatusChangeDialog = (order, statusId) => {
    console.log("Change status:" + statusId);
    setSelectedOrders([]);
    setCurrentOrder(order);
    setStatusDialogOpen(true);
    setCurrentReason(null);
  };

  const openStatusEditDialog = (order) => {
    setSelectedOrders([]);
    setCurrentOrder(order);
    setStatusEditDialogOpen(true);
  };

  const openEditOrder = (order) => {
    setCurrentOrder(order);
    setEditDialogOpen(true);
  };

  const openInfoOrder = (order) => {
    setCurrentOrder(order);
    setOpenInfoDialog(true);
    setSelectedOrders([]);
  };

  const openETA = (order) => {
    setCurrentOrder(order);
    setOpenETA(true);
  };
  const statusHistoryFormatter = (statusHistory) => {
    return `${statusHistory.name} ${
      statusHistory.reason ? statusHistory.reason.name : ""
    }
      ${
        statusHistory.changedBy ? `Updated by ${statusHistory.changedBy} :` : ""
      } ${statusHistory.remark}`;
  };
  const sizeDimensionsFormatter = (order) =>
    order.height && order.length && order.width
      ? `${order.height} x ${order.length} x ${order.width}`
      : "";

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    locations: selectedLocationsFilter.map((location) => location.id),
    carriers: selectedCarriersFilter.map((carrier) => carrier.id),
    retailers: selectedRetailersFilter.map((retailer) => retailer.id),
    postCode: selectedZipCodeFilter,
    statuses: selectedStatusFilter.map((status) => status.id),
    reasons: selectedReasonFilter.map((reason) => reason.id),
    sizes: selectedSizeFilter.map((size) => size.id),
    filterWarning: selectedFilterWarning,
    filterMissingSignature: selectedFilterMissingSignature,
    filterMissingGpsStamp: selectedFilterMissingGpsStamp,
    filterDelayedOrders: selectedFilterDelayedOrders,
    filterTwoFactorOrders: selectedFilterTwoFactorOrders,
    filterClaims: selectedFilterClaimOrders,
    trackingCodes: selectedTrackingCodeFilter,
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
  });

  const resetPageNumber = () => {
    changePagination({ ...pagination, page: 0 });
  };

  const onExportClick = async () => {
    try {
      setExportLoading(true);
      const filters = getFilterData();
      await postRequestUI("/order-exports/admin", filters);
      navigate("/exports");
      setExportLoading(false);
    } catch (e) {
      setExportLoading(false);
    }
  };

  const resendFailedOrders = async () => {
    if (!isFailedOrders) {
      return;
    }
    try {
      const fOrders = getFailedAtCarrierOrders();
      setShowProgress(true);
      await postRequestUI("/resend-failed-orders", fOrders);
      await updateTable();
    } catch (error) {
      const code = error.code;
      const message = error.message;
      setShowError(true);
      if (code === "invalid-argument") {
        setErrorMessage(message);
      }
    } finally {
      setSelectedOrders([]);
      setShowProgress(false);
    }
  };

  const handleMultiSelect = useCallback(
    (selectedId) => {
      if (selectedOrders.includes(selectedId)) {
        setSelectedOrders(selectedOrders.filter((id) => id !== selectedId));
      } else {
        setSelectedOrders([...selectedOrders, selectedId]);
      }
    },
    [selectedOrders]
  );

  const handleOpenStatusDialog = () => {
    if (selectedOrders.length) {
      setStatusDialogOpen(true);
    }
  };

  const handleOpenEtaDialog = () => {
    if (selectedOrders.length) {
      setOpenETA(true);
    }
  };

  const openTwoFactorDialog = (order) => {
    setSelectedOrders([]);
    setCurrentOrder(order);
    setTwoFactorDialogOpen(true);
  };

  const handle2FactorDialogClose = () => {
    setCurrentOrder({});
    setTwoFactorDialogOpen(false);
  };

  /**
   * Data to be passed to table
   */
  const adminTableData = {
    headers: [
      "Product",
      "Size",
      "Dimensions",
      "Delivery Status",
      "Delivery Reason",
      "Retailer",
      "Created At",
      "Description",
      "Pickup date",
      "Pickup address",
      "Delivery Date",
      "City",
      "Delivery Address",
      "Carrier",
      "Tracking code",
      "Second Factor",
      "External Id",
      "Label",
      "Edit Order",
      "Order Info",
      "Edit Status",
      "Update ETA",
    ],
    body: [
      (order) => order.product.name,
      (order) => order.size.name,
      (order) => <Box sx={{ pl: 2 }}>{sizeDimensionsFormatter(order)}</Box>,
      (order) => (
        <Tooltip
          title={
            _.get(order, "statusHistory") ? (
              <div>
                {(_.get(order, "statusHistory") || []).map(
                  ({ name, remark, changedBy, timestamp }) => (
                    <div>
                      <DateFormatter
                        date={timestamp}
                        format="dd/MM/yyyy-HH:mm"
                      />
                      {` ${name} ${
                        changedBy ? `Updated by ${changedBy} :` : ""
                      } ${remark}`}
                      <br />
                    </div>
                  )
                )}
              </div>
            ) : (
              ""
            )
          }
        >
          <Button
            endIcon={<EditIcon />}
            onClick={() => openStatusChangeDialog(order, order.status.id)}
          >
            {order.status.name}
          </Button>
        </Tooltip>
      ),
      (order) => formatReason(order),
      (order) => order.retailer.name,
      (order) => (
        <DateFormatter date={order.createdAt} format={"dd-MM-yyyy HH:mm"} />
      ),
      (order) => order.description,
      (order) => (
        <DateFormatter date={order.pickupDate} format={"dd-MM-yyyy"} />
      ),
      (order) =>
        formatAddress(
          order.pickupAddress.address.postcode,
          order.pickupAddress.address.houseNumber,
          order.pickupAddress.address.houseNumberAdditions,
          order.pickupAddress.address.street
        ),
      (order) => (
        <DateFormatter date={order.deliveryDate} format={"dd-MM-yyyy"} />
      ),
      (order) => order.deliveryAddress.city,
      (order) =>
        formatAddress(
          order.deliveryAddress.postcode,
          order.deliveryAddress.houseNumber,
          order.deliveryAddress.houseAdditions,
          order.deliveryAddress.street
        ),
      (order) => order.carrier.carrierName,
      (order) => order.trackingCode,
      (order) => (
        <Button
          startIcon={order.hasTwoFactorCode ? <LockIcon /> : <VpnKeyIcon />}
          onClick={() => openTwoFactorDialog(order)}
        />
      ),
      (order) => order.externalId,
      (order) =>
        order.isIngested ? (
          <></>
        ) : (
          <ShippingLabelButton trackingCode={order.trackingCode} label="PDF" />
        ),
      (order) => (
        <Button
          endIcon={<EditIcon />}
          data-testid="edit_order_btn"
          onClick={() => openEditOrder(order)}
        ></Button>
      ),
      (order) => (
        <Button
          startIcon={
            order.warnings.length ? <ErrorOutline /> : <VisibilityIcon />
          }
          onClick={() => openInfoOrder(order)}
        />
      ),
      (order) => {
        return !order.statusHistory ||
          (order.statusHistory && order.statusHistory.length <= 1) ? (
          <></>
        ) : (
          <Button
            endIcon={<EditIcon />}
            data-testid="edit_status_btn"
            onClick={() => openStatusEditDialog(order)}
          />
        );
      },
      (order) => (
        <Button startIcon={<UpdateIcon />} onClick={() => openETA(order)} />
      ),
    ],
  };

  const formatReason = (order) => {
    const firstStatus = _.first(order.statusHistory);
    if (!firstStatus) {
      return "";
    }
    return firstStatus.reason ? firstStatus.reason.name : "";
  };
  const getOrderByTrackingCode = async (trackingCode) => {
    try {
      setShowProgress(true);
      const result = await getRequestUI(`/admin/order/search/${trackingCode}`);

      if (result) {
        setOrders([result]);
      } else {
        setOrders([]);
      }
    } catch (error) {
      const message = error.message;
      setShowError(true);
      if (message) {
        setErrorMessage(message);
      }
    } finally {
      setShowProgress(false);
    }
  };

  const getOrdersByTrackingCodes = async (trackingCodes) => {
    try {
      setShowProgress(true);
      const orders = await getRequestUI(
        `/admin/order/search-tracking-codes?trackingCodes=${trackingCodes}`
      );

      if (orders) {
        setOrders(orders);
        setTotalRowsNumber(orders.length);
      } else {
        setOrders([]);
        setTotalRowsNumber(0);
      }
    } catch (error) {
      const message = error.message;
      setShowError(true);
      if (message) {
        setErrorMessage(message);
      }
    } finally {
      setShowProgress(false);
    }
  };

  const onCloseErrorMessage = () => {
    setErrorMessage("");
    setShowError(false);
  };

  return (
    <MainBox isFullWidth={true}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        onClose={onCloseErrorMessage}
        autoHideDuration={TIME_SHOW_ERROR}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      {!prerequisites.isLoading && !prerequisites.error && (
        <>
          <UpdateOrderStatus
            statusDialogOpen={statusDialogOpen}
            handleStatusDialogClose={handleStatusDialogClose}
            setCurrentStatus={setCurrentStatus}
            currentStatus={currentStatus}
            statuses={prerequisites.data.statuses}
            saveStatus={saveStatus}
            showProgress={updatingStatus}
            status={currentStatus}
            reasons={prerequisites.data.reasons}
            setCurrentReason={setCurrentReason}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
          <EditOrderStatus
            dialogOpen={editStatusDialog}
            editStatusDialogClose={handleStatusEditDialogClose}
            statusHistory={currentOrder.statusHistory}
            reasons={prerequisites.data.reasons}
            statuses={prerequisites.data.statuses}
            editStatus={handleEditStatus}
            deleteStatus={deleteStatus}
            showProgress={updatingStatus}
            setShowError={setShowError}
            setErrorMessage={setErrorMessage}
          />
        </>
      )}
      <OrderSignatureDialog
        orderId={currentOrder.id}
        isDialogOpen={signatureDialogOpened}
        handleClose={handleSignatureDialogClose}
        setShowError={setShowError}
        setErrorMessage={setErrorMessage}
        setShowProgress={setShowProgress}
      />
      <OrderETA
        setSelectedOrders={setSelectedOrders}
        selectedOrders={
          selectedOrders.length > 0 ? selectedOrders : [currentOrder.id]
        }
        isOpenETA={isOpenETA}
        handleCloseETA={handleCloseETA}
        setShowProgress={setShowProgress}
        setShowError={setShowError}
        setErrorMessage={setErrorMessage}
        updateOrders={updateTable}
      />

      {!_.isEmpty(currentOrder) && (
        <>
          <Order2FactorCode
            orderId={currentOrder.id}
            isDialogOpen={twoFactorDialogOpen}
            handleClose={handle2FactorDialogClose}
          />
          <EditOrder
            isEditOrder={editDialogOpen}
            order={currentOrder}
            handleCloseEdit={handleCloseEdit}
            setShowError={setShowError}
            setErrorMessage={setErrorMessage}
            updateOrders={updateTable}
          />
          <OrderInfo
            order={currentOrder}
            isOpenOrderInfo={openInfoDialog}
            handleCloseInfo={handleCloseInfo}
            setShowError={setShowError}
            setErrorMessage={setErrorMessage}
            setStatusDialogOpen={setStatusDialogOpen}
            showGpsStamp={true}
            showCarrierInfo={true}
            showWarnings={true}
            showIsCodeExist={true}
            setShowProgress={setShowProgress}
            statusHistoryFormatter={statusHistoryFormatter}
          />
        </>
      )}
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>

      {!prerequisites.isLoading && !prerequisites.error && (
        <Filters
          setTitle={"Orders"}
          locations={prerequisites.data.locations}
          carriers={prerequisites.data.carriers}
          retailers={prerequisites.data.retailers}
          statuses={prerequisites.data.statuses}
          reasons={prerequisites.data.reasons}
          sizes={prerequisites.data.sizes}
          selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
          setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
          selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
          setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
          onExportClick={onExportClick}
          exportLoading={exportLoading}
          selectedLocationsFilter={selectedLocationsFilter}
          setSelectedLocationsFilter={setSelectedLocationsFilter}
          selectedCarriersFilter={selectedCarriersFilter}
          setSelectedCarriersFilter={setSelectedCarriersFilter}
          selectedRetailersFilter={selectedRetailersFilter}
          setSelectedRetailersFilter={setSelectedRetailersFilter}
          selectedTrackingCodeFilter={selectedTrackingCodeFilter}
          setSelectedTrackingCodeFilter={setSelectedTrackingCodeFilter}
          getOrderByTrackingCodes={getOrdersByTrackingCodes}
          selectedZipCodeFilter={selectedZipCodeFilter}
          setSelectedZipCodeFilter={setSelectedZipCodeFilter}
          selectedStatusFilter={selectedStatusFilter}
          setSelectedStatusFilter={setSelectedStatusFilter}
          selectedReasonFilter={selectedReasonFilter}
          setSelectedReasonFilter={setSelectedReasonFilter}
          selectedSizeFilter={selectedSizeFilter}
          setSelectedSizeFilter={setSelectedSizeFilter}
          selectedFilterWarning={selectedFilterWarning}
          setSelectedFilterWarning={setSelectedFilterWarning}
          selectedFilterMissingSignature={selectedFilterMissingSignature}
          setSelectedFilterMissingSignature={setSelectedFilterMissingSignature}
          selectedFilterMissingGpsStamp={selectedFilterMissingGpsStamp}
          setSelectedFilterMissingGpsStamp={setSelectedFilterMissingGpsStamp}
          selectedFilterDelayedOrders={selectedFilterDelayedOrders}
          setSelectedFilterDelayedOrders={setSelectedFilterDelayedOrders}
          selectedFilterTwoFactorOrders={selectedFilterTwoFactorOrders}
          setSelectedFilterTwoFactorOrders={setSelectedFilterTwoFactorOrders}
          selectedFilterClaimOrders={selectedFilterClaimOrders}
          setSelectedFilterClaimOrders={setSelectedFilterClaimOrders}
          onApplyFilter={resetPageNumAndUpdateTable}
        />
      )}

      <OrdersTable
        headers={adminTableData.headers}
        rowData={orders}
        cellComponents={adminTableData.body}
        multiSelect
        selectedRows={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        isFailedOrders={isFailedOrders}
        pagination={pagination}
        totalRowsNumber={totalRowsNumber}
        changePagination={changePagination}
        onResendFailedOrders={resendFailedOrders}
        onSelect={handleMultiSelect}
        onMultiUpdate={handleOpenStatusDialog}
        onMultiUpdateETA={handleOpenEtaDialog}
      />
    </MainBox>
  );
}

export default Orders;
