import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import { putRequestUI } from "../../../library/node_modules/common-utils/utils/api";
import useTheme from "@mui/material/styles/useTheme";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";

const useStyles = (theme) => ({
  container: {
    height: "240px",
    width: "238px",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
});

const OrderETA = ({
  setSelectedOrders,
  selectedOrders,
  isOpenETA,
  handleCloseETA,
  setShowError,
  setErrorMessage,
  updateOrders,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const [fromTime, setFromTime] = useState("10:00");
  const [toTime, setToTime] = useState("10:00");

  const selectDate = (date) => {
    setSelectedDate(date);
  };

  const updateETA = async () => {
    setShowError(false);
    try {
      const body = {
        orderIds: selectedOrders,
        date: selectedDate.toISODate(), // Use ISO format
        from: fromTime,
        to: toTime,
      };
      await putRequestUI("/update-eta", body);
      handleCloseETA();
      updateOrders();
      setSelectedOrders([]);
    } catch (error) {
      setShowError(true);
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        open={isOpenETA}
        aria-labelledby="status-dialog-title"
        onClose={handleCloseETA}
      >
        <DialogTitle id="status-dialog-title">Update Order ETA</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between" sx={styles.container}>
            <Grid container xs={12}>
              <Grid>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      label="Select Date"
                      value={selectedDate}
                      onChange={selectDate}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} flexDirection="column">
              <TextField
                id="time"
                label="From"
                type="time"
                variant="standard"
                fullWidth
                defaultValue={fromTime}
                sx={styles.input}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={(e) => setFromTime(e.target.value)}
              />
              <TextField
                id="time"
                label="To"
                type="time"
                variant="standard"
                fullWidth
                defaultValue={toTime}
                sx={styles.input}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={(e) => setToTime(e.target.value)}
              />
            </Grid>
            <Button onClick={updateETA} color="primary">
              Update
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrderETA;
